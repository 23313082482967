/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import { Link } from "react-router-dom";
import ApproveMentor from "./approveMentor";
import test from "./mentorTable";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Mentor Name",
    dataIndex: "mentor",
    key: "mentor",
    width: 250,
    fixed: "left",
    render: (mentor) => (
      <Link
        to
        to={{ pathname: `/mentors/${mentor[1]}`, state: { props: mentor[2] } }}
      >
        {mentor[0]}
      </Link>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 280,
  },
  {
    title: "Approve",
    dataIndex: "approve",
    key: "approve",
    width: 280,
    render: (approve) => (
      <>
        {approve[1] ? (
          <div>
            <CheckOutlined
              onClick={ApproveMentor.bind(
                this,
                approve[0],
                approve[2],
                approve[3],
                "approve"
              )}
              style={{ fontSize: "16px", color: "green", marginRight: "5%" }}
            />
            <CloseOutlined
              onClick={test}
              style={{ fontSize: "16px", color: "red" }}
            />
          </div>
        ) : (
          "-"
        )}
      </>
    ),
  },
];

export default columns;
