import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Display1 from "../../components/display1";
import MenteeProfile from "../../components/display1/menteeProfile";
import Display2 from "../../components/display2";
import Display3 from "../../components/display3";
import AdminApproval from "../../components/display3/adminApproval";
// SK mentee profile added in the display 3 screen
import MenteeProfile3 from "../../components/display3/menteeProfile3";
import "../Home/home.css";

const { Header, Sider } = Layout;

export default class Leftbar extends React.Component {
  state = {
    collapsed: false,
    reviewData: [],
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <Menu theme="dark" mode="inline">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/">Orders</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<VideoCameraOutlined />}>
              <Link to="/mentors">Mentors</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<UploadOutlined />}>
              <Link to="/reviews">Reviews</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            {React.createElement(
              this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: this.toggle,
              }
            )}
          </Header>
          <Switch>
            <Redirect exact from="/" to="/orders" />
            <Route exact path="/orders" component={Display1} />
            <Route
              exact
              path="/mentee/:userid"
              render={(props) => <MenteeProfile {...props} />}
            />
            <Route
              exact
              path="/mentee3/:userid"
              render={(props) => <MenteeProfile3 {...props} />}
            />
            <Route path="/mentors" component={Display2} />
            <Route
              exact
              path="/reviews/filters/:orderId"
              component={Display3}
            />
            <Route path="/reviews" component={Display3} />
            <Route
              path="/feedback/:userid"
              render={(props) => <AdminApproval {...props} />}
            />
          </Switch>
        </Layout>
      </>
    );
  }
}
