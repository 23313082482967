import React from "react";
import "antd/dist/antd.css";
import "./home.css";
import { Layout } from "antd";
import Leftbar from "../Leftbar";
import Navbar from "../../containers/Navbar/Loadable";

export default class Home extends React.Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <>
        <Navbar />
        <Layout style={{ height: "auto" }}>
          <Leftbar />
        </Layout>
      </>
    );
  }
}
