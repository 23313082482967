/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/*
 * Review Table
 *
 */

import React, { useState, useEffect } from "react";
import { Select, Tag } from "antd";
import { SearchTableInput } from "ant-table-extensions";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import columns from "./columns";

export default function TableFilters({ dataSource, handleChangeDataSource }) {
  //price
  const priceOptions = [75, 99, 199, 999];
  const [selectedPriceItems, setSelectedPriceItems] = useState([]);
  const filteredPriceOptions = priceOptions.filter(
    (o) => !selectedPriceItems.includes(o)
  );
  //product
  const productOptions = [
    "Student Pricing",
    "Resume Review",
    "Career Elevation",
    "Executive Branding",
  ];
  const [selectedProductItems, setSelectedProductItems] = useState([]);
  const filteredProductOptions = productOptions.filter(
    (o) => !selectedProductItems.includes(o)
  );

  const [tagItems, setTagItems] = useState([]);
  const [tagLabels, setTagLabels] = useState([]);

  useEffect(() => {
    let index = tagItems.indexOf("Clear Filters");
    if (index !== -1 && tagItems.length === 1) {
      setTagItems([]);
    } else if (tagItems.length > 0) {
      let items = [...tagItems];
      if (index === -1) items.push("Clear Filters");
      setTagItems(items);
    }
  }, [selectedPriceItems, selectedProductItems]);

  // Filtering functions
  const handleFilteredData = (priceData, productData) => {
    // setting tag labels
    var priceLabel = new Array(priceData.length).fill("Price: ");
    var productLabel = new Array(productData.length).fill("Product: ");
    setTagLabels(priceLabel.concat(productLabel));

    // setting tags
    var filteredEventsPrice =
      priceData.length > 0
        ? dataSource.filter((dta) => priceData.includes(dta.price))
        : dataSource;
    var filteredEventsProduct =
      productData.length > 0
        ? dataSource.filter((dta) => productData.includes(dta.product))
        : dataSource;
    const filteredEvents = filteredEventsPrice.filter((value) =>
      filteredEventsProduct.includes(value)
    );
    handleChangeDataSource(filteredEvents);
  };
  const handlePriceChange = (priceItems) => {
    setSelectedPriceItems(priceItems);
    setTagItems(priceItems.concat(selectedProductItems));
    handleFilteredData(priceItems, selectedProductItems);
  };

  const handleProductChange = (productItems) => {
    setSelectedProductItems(productItems);
    setTagItems(selectedPriceItems.concat(productItems));
    handleFilteredData(selectedPriceItems, productItems);
  };

  const handleTags = (item, index, e) => {
    e.preventDefault();
    const filterItem = item;
    if (filterItem === "Clear Filters") {
      setSelectedPriceItems([]);
      setSelectedProductItems([]);
      setTagItems([]);
      setTagLabels([]);
      handleChangeDataSource(dataSource);
    } else {
      let itemsOfPrice = selectedPriceItems.filter((a) => a !== filterItem);
      let itemsOfProduct = selectedProductItems.filter((a) => a !== filterItem);
      let itemsOfTags = tagItems.filter((a) => a !== filterItem);
      setSelectedPriceItems(itemsOfPrice);
      setSelectedProductItems(itemsOfProduct);
      setTagItems(itemsOfTags);
      if (itemsOfTags.length > 0) {
        handleFilteredData(itemsOfPrice, itemsOfProduct);
      } else {
        handleChangeDataSource(dataSource);
      }
    }
  };
  return (
    <>
      <div className="reviewTable__header">
        <span className="reviewTable__header-title">
          <span className="reviewTable__header-left">
            <span className="reviewTable__header-text">
              <h4
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  textAlign: "left",
                }}
              >
                Filter
              </h4>
            </span>
            Price
            <span
              className="reviewTable__header-filter"
              style={{ width: "50%" }}
            >
              <Select
                suffixIcon={
                  <DownOutlined
                    style={{
                      color: "#1890ff",
                    }}
                  />
                }
                showArrow
                mode="multiple"
                label="Pro"
                value={selectedPriceItems}
                onChange={handlePriceChange}
                style={{ width: "100%" }}
                className="dont-show"
              >
                {filteredPriceOptions.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </span>
            Product
            <span
              className="reviewTable__header-filter"
              style={{ width: "110%" }}
            >
              <Select
                suffixIcon={
                  <DownOutlined
                    style={{
                      color: "#1890ff",
                    }}
                  />
                }
                showArrow
                mode="multiple"
                value={selectedProductItems}
                onChange={handleProductChange}
                style={{ width: "100%" }}
                className="dont-show"
              >
                {filteredProductOptions.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </span>
          </span>
        </span>

        <span className="reviewTable__header-search">
          <SearchTableInput
            columns={columns}
            dataSource={dataSource} // Original dataSource
            setDataSource={handleChangeDataSource} // Newly created setSearchDataSource from useState hook
            fuzzySearch="true"
            fuseProps={{
              keys: [
                "key",
                "product",
                "created",
                "price",
                "status",
                "firstName",
                "fname",
              ],
              threshold: 0.0,
              location: 0.0,
              distance: 0.0,
              ignoreLocation: true,
            }}
            inputProps={{
              placeholder: "Search......",
              suffix: (
                <SearchOutlined
                  style={{
                    fontSize: 16,
                    color: "#1890ff",
                  }}
                />
              ),
              style: {
                margin: "0 15px",
              },
            }}
            enterButton
          />
        </span>
      </div>
      {tagItems.map((item, index) => (
        <Tag id={item} closable onClose={handleTags.bind(this, item, index)}>
          {index + 1 !== tagItems.length && tagLabels[index]}
          {item}
        </Tag>
      ))}
    </>
  );
}
