/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/*
 * Review Table
 *
 */

import React, { useState, useEffect } from "react";
import { Select, Tag } from "antd";
import { SearchTableInput } from "ant-table-extensions";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import columns from "./columns";
import { statusMap } from "../../../utils/statusFlag";
import { printExperience } from "../../../utils/experience";

export default function TableFilters({ dataSource, handleChangeDataSource }) {
  //round
  const roundOptions = [1, 2, 3, 4, 5];
  const [selectedRoundItems, setSelectedRoundItems] = useState([]);
  const filteredRoundOptions = roundOptions.filter(
    (o) => !selectedRoundItems.includes(o)
  );
  //role
  const roleOptions = ["Full Time", "Part Time", "Internship"];
  const [selectedRoleItems, setSelectedRoleItems] = useState([]);
  const filteredRoleOptions = roleOptions.filter(
    (o) => !selectedRoleItems.includes(o)
  );

  //experience
  const experienceOptions = [
    ...Array.from({ length: 10 }, (_, i) => i + 1),
    15,
    20,
    25,
  ];
  const [selectedExperienceItems, setSelectedExperienceItems] = useState([]);
  const filteredExperienceOptions = experienceOptions.filter(
    (o) => !selectedExperienceItems.includes(o)
  );

  //review name
  const reviewOptions = [
    "Front End Engineer",
    "Back End Engineer",
    "Full Stack Engineer",
  ];
  const [selectedReviewItems, setSelectedReviewItems] = useState([]);
  const filteredReviewOptions = reviewOptions.filter(
    (o) => !selectedReviewItems.includes(o)
  );

  //status
  const statusOptions = [
    statusMap.waitingForDetails,
    statusMap.waitingForResume,
    statusMap.waitingForReview,
    statusMap.pendingMentor,
    statusMap.mentorAssigned,
    statusMap.inReview,
    statusMap.sentForApproval,
    statusMap.done,
  ];
  const [selectedStatusItems, setSelectedStatusItems] = useState([]);
  const filteredStatusOptions = statusOptions.filter(
    (o) => !selectedStatusItems.includes(o)
  );

  const [tagItems, setTagItems] = useState([]);
  const [tagLabels, setTagLabels] = useState([]);

  useEffect(() => {
    let index = tagItems.indexOf("Clear Filters");
    if (index !== -1 && tagItems.length === 1) {
      setTagItems([]);
    } else if (tagItems.length > 0) {
      let items = [...tagItems];
      if (index === -1) items.push("Clear Filters");
      setTagItems(items);
    }
  }, [
    selectedRoundItems,
    selectedStatusItems,
    selectedRoleItems,
    selectedExperienceItems,
    selectedReviewItems,
  ]);

  // Filtering functions
  const handleFilteredData = (
    roundData,
    roleData,
    expData,
    reviewData,
    statusData
  ) => {
    // setting tag labels
    var roundLabel = new Array(roundData.length).fill("Round: ");
    var roleLabel = new Array(roleData.length).fill("Role: ");
    var expLabel = new Array(expData.length).fill("Experience: ");
    var reviewLabel = new Array(reviewData.length).fill("Title: ");
    var statusLabel = new Array(statusData.length).fill("Status: ");
    setTagLabels(
      roundLabel.concat(roleLabel, expLabel, reviewLabel, statusLabel)
    );

    // setting tags
    var filteredEventsRound =
      roundData.length > 0
        ? dataSource.filter((dta) => roundData.includes(dta.roundNo))
        : dataSource;
    var filteredEventsRole =
      roleData.length > 0
        ? dataSource.filter((dta) => roleData.includes(dta.role))
        : dataSource;
    var filteredEventsExp =
      expData.length > 0
        ? dataSource.filter((dta) => expData.includes(dta.experience))
        : dataSource;
    var filteredEventsReview =
      reviewData.length > 0
        ? dataSource.filter((dta) => reviewData.includes(dta.productTitle))
        : dataSource;
    var filteredEventsStatus =
      statusData.length > 0
        ? dataSource.filter((dta) => statusData.includes(dta.statusDetails))
        : dataSource;
    var filteredData = [
      filteredEventsRound,
      filteredEventsRole,
      filteredEventsExp,
      filteredEventsReview,
      filteredEventsStatus,
    ];
    const filteredEvents = filteredData.reduce(
      (filteredEventsRound, filteredEventsRole) =>
        filteredEventsRound.filter((filteredEventsExp) =>
          filteredEventsRole.includes(filteredEventsExp)
        )
    );
    handleChangeDataSource(filteredEvents);
  };
  const handleRoundChange = (roundItems) => {
    setSelectedRoundItems(roundItems);
    setTagItems(
      roundItems.concat(
        selectedRoleItems,
        selectedExperienceItems,
        selectedReviewItems,
        selectedStatusItems
      )
    );
    handleFilteredData(
      roundItems,
      selectedRoleItems,
      selectedExperienceItems,
      selectedReviewItems,
      selectedStatusItems
    );
  };

  const handleRoleChange = (roleItems) => {
    setSelectedRoleItems(roleItems);
    setTagItems(
      selectedRoundItems.concat(
        roleItems,
        selectedExperienceItems,
        selectedReviewItems,
        selectedStatusItems
      )
    );
    handleFilteredData(
      selectedRoundItems,
      roleItems,
      selectedExperienceItems,
      selectedReviewItems,
      selectedStatusItems
    );
  };
  const handleExperienceChange = (experienceItems) => {
    setSelectedExperienceItems(experienceItems);
    setTagItems(
      selectedRoundItems.concat(
        selectedRoleItems,
        experienceItems,
        selectedReviewItems,
        selectedStatusItems
      )
    );
    handleFilteredData(
      selectedRoundItems,
      selectedRoleItems,
      experienceItems,
      selectedReviewItems,
      selectedStatusItems
    );
  };
  const handleReviewChange = (reviewItems) => {
    setSelectedReviewItems(reviewItems);
    setTagItems(
      selectedRoundItems.concat(
        selectedRoleItems,
        selectedExperienceItems,
        reviewItems,
        selectedStatusItems
      )
    );
    handleFilteredData(
      selectedRoundItems,
      selectedRoleItems,
      selectedExperienceItems,
      reviewItems,
      selectedStatusItems
    );
  };
  const handleStatusChange = (statusItems) => {
    setSelectedStatusItems(statusItems);
    setTagItems(
      selectedRoundItems.concat(
        selectedRoleItems,
        selectedExperienceItems,
        selectedReviewItems,
        statusItems
      )
    );
    handleFilteredData(
      selectedRoundItems,
      selectedRoleItems,
      selectedExperienceItems,
      selectedReviewItems,
      statusItems
    );
  };
  const handleTags = (item, index, e) => {
    e.preventDefault();
    const filterItem = item;
    if (filterItem === "Clear Filters") {
      setSelectedRoundItems([]);
      setSelectedStatusItems([]);
      setSelectedRoleItems([]);
      setSelectedExperienceItems([]);
      setSelectedReviewItems([]);
      setTagItems([]);
      setTagLabels([]);
      handleChangeDataSource(dataSource);
    } else {
      let itemsOfRound = selectedRoundItems.filter((a) => a !== filterItem);
      let itemsOfStatus = selectedStatusItems.filter((a) => a !== filterItem);
      let itemsOfRole = selectedRoleItems.filter((a) => a !== filterItem);
      let itemsOfExperience = selectedExperienceItems.filter(
        (a) => a !== filterItem
      );
      let itemsOfReview = selectedReviewItems.filter((a) => a !== filterItem);
      let itemsOfTags = tagItems.filter((a) => a !== filterItem);
      setSelectedRoundItems(itemsOfRound);
      setSelectedStatusItems(itemsOfStatus);
      setSelectedExperienceItems(itemsOfExperience);
      setTagItems(itemsOfTags);
      setSelectedRoleItems(itemsOfRole);
      setSelectedReviewItems(itemsOfReview);
      if (itemsOfTags.length > 0) {
        handleFilteredData(
          itemsOfRound,
          itemsOfRole,
          itemsOfExperience,
          itemsOfReview,
          itemsOfStatus
        );
      } else {
        handleChangeDataSource(dataSource);
      }
    }
  };
  return (
    <>
      <div className="reviewTable__header">
        <span className="reviewTable__header-title">
          <span className="reviewTable__header-left">
            <span className="reviewTable__header-text">
              <h4
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  textAlign: "left",
                }}
              >
                Filter
              </h4>
            </span>
            Round
            <span className="reviewTable__header-filter">
              <Select
                suffixIcon={
                  <DownOutlined
                    style={{
                      color: "#1890ff",
                    }}
                  />
                }
                showArrow
                mode="multiple"
                label="Pro"
                value={selectedRoundItems}
                onChange={handleRoundChange}
                style={{ width: "100%" }}
                className="dont-show"
              >
                {filteredRoundOptions.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </span>
            Role
            <span
              className="reviewTable__header-filter"
              style={{ width: "30%" }}
            >
              <Select
                suffixIcon={
                  <DownOutlined
                    style={{
                      color: "#1890ff",
                    }}
                  />
                }
                showArrow
                mode="multiple"
                label="Pro"
                value={selectedRoleItems}
                onChange={handleRoleChange}
                style={{ width: "100%" }}
                className="dont-show"
              >
                {filteredRoleOptions.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </span>
            Experience
            <span
              className="reviewTable__header-filter"
              style={{ width: "27%" }}
            >
              <Select
                suffixIcon={
                  <DownOutlined
                    style={{
                      color: "#1890ff",
                    }}
                  />
                }
                showArrow
                mode="multiple"
                label="Pro"
                value={selectedExperienceItems}
                onChange={handleExperienceChange}
                style={{ width: "100%" }}
                className="dont-show"
              >
                {filteredExperienceOptions.map((item) => (
                  <Select.Option key={item} value={item}>
                    {printExperience(item, true)}
                  </Select.Option>
                ))}
              </Select>
            </span>
          </span>

          <span className="reviewTable__header-right">
            Status
            <span
              className="reviewTable__header-filter"
              style={{ width: "37%" }}
            >
              <Select
                suffixIcon={
                  <DownOutlined
                    style={{
                      color: "#1890ff",
                    }}
                  />
                }
                showArrow
                mode="multiple"
                label="Pro"
                value={selectedStatusItems}
                onChange={handleStatusChange}
                style={{ width: "100%" }}
                className="dont-show"
              >
                {filteredStatusOptions.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </span>
            Review
            <span
              className="reviewTable__header-filter"
              style={{ width: "30%" }}
            >
              <Select
                suffixIcon={
                  <DownOutlined
                    style={{
                      color: "#1890ff",
                    }}
                  />
                }
                showArrow
                mode="multiple"
                label="Pro"
                value={selectedReviewItems}
                onChange={handleReviewChange}
                style={{ width: "100%" }}
                className="dont-show"
              >
                {filteredReviewOptions.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </span>
          </span>
        </span>

        <span className="reviewTable__header-search">
          <SearchTableInput
            columns={columns}
            dataSource={dataSource} // Original dataSource
            setDataSource={handleChangeDataSource} // Newly created setSearchDataSource from useState hook
            fuzzySearch="true"
            fuseProps={{
              keys: [
                "product",
                "productTitle",
                "mentor",
                "mentee",
                "status",
                "tags",
              ],
              threshold: 0.0,
              location: 0.0,
              distance: 0.0,
              ignoreLocation: true,
            }}
            inputProps={{
              placeholder: "Search......",
              suffix: (
                <SearchOutlined
                  style={{
                    fontSize: 16,
                    color: "#1890ff",
                  }}
                />
              ),
              style: {
                margin: "0 15px",
              },
            }}
            enterButton
          />
        </span>
      </div>
      {tagItems.map((item, index) => (
        <Tag id={item} closable onClose={handleTags.bind(this, item, index)}>
          {index + 1 !== tagItems.length && tagLabels[index]}
          {item}
        </Tag>
      ))}
    </>
  );
}
